import { dialog_add, dialog_del, dialog_other, dialog_staff } from './dialog.js'
import { approval } from './tool.js'
// import { detail_header } from './detail.js'

export default {
  // 应用名称
  app_name:{
    'xiangmuguanli': '项目管理',
    'yitou': '已投公司',
    'xiangmu': '项目',
    'jijinguanli': '基金管理',
    'jijin': '基金',
    'touzizheguanli': '投资者管理',
    'touzizhe': '投资者', // 和投资者管理同应用
    'shujushouji': '数据收集',
    'jingzhuntouziyun': '鲸准投资云',

  },
  //列表页
  list_page:{
    test: 123,
    header:{
      'liebiao_shitu': '列表视图',
      'kanban_shitu': '看板视图',
      'kanban': '看板',
      'liebiao': '列表',
      'ziduanpeizhi': '字段配置',
      'xiugaipeizhi_qipao': '修改配置将影响所有查看者',
      'yincangziduan': '隐藏字段',
      'xianshiziduan': '显示字段',
      'yincangbiaoti': '隐藏标题',
      'xianshibiaoti': '显示标题',
      'shezhishaixuantiaojian':'设置筛选条件',
      'tianjiatiaojian': '添加条件',
      'shezhipaixutiaojian': '设置排序条件',
      'shezhipaixutiaojian_qipao': '排序条件为空时按照数据创建时间倒序排列',
      'xuanzetiaojian': '选择条件',
      'fenzuyiju': '分组依据',
      'xuanzefenzuyiju': '选择分组依据',
      'qiehuanfenzu_tip': '切换分组条件，字段配置将被重置，是否继续？'
    },
    view: {
      'yincangcebianlan': '隐藏侧边栏',
      'xianshicebianlan': '显示侧边栏',
      'xinjianshitu': '新建视图',
      'suodingshitu_qipao': '视图已被锁定，解锁后可修改视图配置',
      'chongmingming': '重命名',
      'fuzhishitu': '复制视图',
      'baohushitu': '保护视图',
      'gonggongshitu': '公共视图',
      'gonggongshitu_miaoshu': '所有成员可修改配置',
      'suodingshitu': '锁定视图',
      'suodingshitu_miaoshu': '所有成员都不能修改配置',
      'gerenshitu': '个人视图',
      'gerenshitu_qipao': '他人创建的视图不可转为你的个人视图',
      'gerenshitu_miaoshu': '仅自己可见',
      'quanbuzhuangeren_qipao': '全部视图不可转为你的个人视图',
      'gerenshitu_tubiaoqipao': '此视图为个人视图，仅你可查看',
      'shanchushitu': '删除视图',
      'quedingshanchushitu': '确认要删除视图{viewName}吗',
      'shezhigerenshitu': '设置个人视图',
      'shezhigerenshitu_tankuangneirong': '设置后，其他成员将无法查看此视图。',
      'suodingshitu_tankuangneirong': '锁定视图后，所有成员不可修改字段、分组、筛选等视图配置。',
      'shituyisuoding': '视图已锁定',
      'suodingshitu_caozuotishi': '当前视图已被锁定，无法修改字段、分组、筛选等视图配置。',
      'lingcunweixinshitu': '另存为新视图',
      'jiesuoshitu': '解锁视图',
      'jiesuoshitu_tankuangneirong': '解锁后，所有成员可修改字段、分组、筛选等视图配置。',
      'shituyijiesuo': '视图已解锁',
      'shitumingcheng_bunengweikong': '视图名称不能为空',
      'biaogeshitu': '表格视图'
    },
    search: {
      'sousuojilu': '搜索记录',
      'qingchujilu': '清除记录',
      'zonghe': '综合',
      'querenshanchu': '确认删除全部搜索记录？',
      'sousuoxiangmu_zhanweifu': '搜索公开信息或查找本地项目',
      'sousuojijin_zhanweifu': '搜索基金简称、全称或编号',
      'sousuotouzizhe_zhanweifu': '搜索或查找本地投资者',
      'zuixinhuotou': '最新获投',
      'zuixintouzi': '最新投资',
      'sousuo_zanwushuju': '未找到{name}？<br />可尝试更换关键词，或确认是否具有该{name}访问权限',
      'xiangmu': '项目',
      'jijin': '基金'
      
    },
    list_board: {
      'tuodongxiangmu_tip': '拖动项目，快速流转阶段',
      'tuodongtouzizhe_tip': '拖动投资者，快速变更状态',
      'jieduanweiwancheng': '[{stageName}]阶段还未完成',
      'zhuanrutouhou': '转入投后',
      'querenzhuanrutouhou': '确认转入投后?',
      // 'zhuanrutouhou_tip': '首次投资项目,转入投后将自动创建投后项目数据<br />项目信息及文档等将同步转入投后。<br />*同一项目再次转入投后，请先调整对应投后数据',
      'zhuanrutouhou_tip': '首次投资项目,转入投后将自动创建投后项目数据<br />项目信息及文档等将同步转入投后<br />投资流程将锁定不可变更，如需解锁，可联系管理员。<br />*同一项目再次转入投后，请先调整对应投后数据',
      'jieduanquanxian': '您没有权限修改阶段，请联系项目负责人开通权限！',
      'qingxuanzejieduan': '请选择阶段',
    },
    list_table: {
      'biangengfuzeren': '变更负责人',
      'daochushuju': '导出数据',
      'tianjiachengyuan_title': '为“{ checkedName }”等{length }个 {codeName} 添加成员',
      'bianjiquanxian_tip': '您没有编辑权限，请联系项目负责人开通权限！',
      'ziduan_required': '{fieldName}为必填字段，不能为空',
      'shangchuangwenjian_limit': '`{fieldName}最多只能上传 200 个文件`',
      'jinrutouhou': '进入投后',
      'fangqixiangmu': '放弃项目',
      'quanbutuichu': '全部退出',
      'bufentuichu': '部分退出',
      'xiugaitouzizhe_fullname': '修改投资者全称',
      'xiugaizhutigongsi': '修改主体公司',
      'touzizhe_placeholder': '请输入投资者主体全称',
      'other_placeholder': '请输入要投资的公司主体全称',
      'touzizhechuangjian_tooltip': '投资者创建成功后，您可以在投资者详情中查看公开信息',
      'xiangmuchuangjian_tooltip': '项目创建成功后，您可以在项目详情中查看公开信息',
      'chazhaotouzizhe_tip': '请输入您想查找的投资者主体 <br />若投资者为个人，请直接点击确定',
      'chazhaotouzizhe_empty': '没有找到您查找的投资者主体 <br />您也可以根据输入的内容创建投资者主体全称',
      'chazhaogongsi_tip': '请输入您想查找的公司 <br />或根据输入的内容创建主体公司',
      'chazhaogongsi_empty': '没有找到您查找的公司 <br />您也可以根据输入的内容创建主体公司',
      'zhutigongweikong': '主体公司不能为空',
    }
  },
  // 通用按钮
  general_btn: {
    'daochu': '导出',
    'daochuzhong': '导出中',
    'queding': '确定',
    'quxiao': '取消',
    'xiayibu': '下一步',
    'shangyibu': '上一步',
    'wancheng': '完成',
    'shanchu': '删除',
    'sousuo': '搜索',
    'paixu': '排序',
    'shaixuan': '筛选',
    'guanbi': '关闭',
    'qingchu': '清除',
    'chakanquanbu': '查看全部',
    'chakangengduo': '查看更多',
    'jixu': '继续',
    'zhidaole': '知道了',
    'tianjiachengyuan':'添加成员',
    'tianjiaxuanxiang': '添加选项',
    'chuangjianricheng': '创建日程',
    'guanbi': '关闭',
    'qingkong': '清空',
    'chakanxiangqing': '查看详情',
    'xiangqing': '详情',
    'chuangjian': '创建',
    'yichu': '移除',
    'fenxiang': '分享',
    'faqiqunliao': '发起群聊',
    'shenqing': '申请',
    'tijiao': '提交',
    'bianji': '编辑',
    'tianjia': '添加',
    'faqi': '发起',
    'fanhuiliebiao': '返回列表',

    
    

  },
  // 通用toast提示
  general_toast: {
    'biangengchenggong': '变更成功！',
    'tianjiachenggong': '添加成功！',
    'xuanzexiangmu': '请先选择项目！',
    'shanchuchenggong': '删除成功',
    'shanchushibai': '删除失败',
    'shanchuanchenggong': '{fileName}上传成功',
    'xitongyichang': '系统异常',
    'xiugaishibai': '修改失败',
    'xiugaichenggong': '修改成功',
    'caozuochenggong': '操作成功',
    'fuzeren_setting': '负责人设置成功！',
    'chengyuanquanxian_setting': '成员权限更改成功！',
    'yishanchu': '已删除！',
    'fasongchenggong': '申请发送成功',
    'chuangjianquliao_ing': '正在创建群聊中...',
    'wufafaqi_chat': '您未在该项目成员中，无法发起群聊',
    'mingchengbunengweikong': '名称不能为空',
    'zaifeishudakai': '请在飞书应用内打开',
    'shujubucunzai': '数据不存在!',
    'qingxuanze_staff': '请选择人员',
    'fujian_sizelimit': '附件大小不能超过50MB',
    'qingxuanze_approlval': '请选择审批名称',
    'toupiaochenggong': '投票成功',
    'yichuchenggong': '移除成功',
    'toupiaotixing': '投票提醒已发送',
    'xuanxiangzhi_empty': '选项值不可为空',
    'faqichenggong': '发起成功',
    'bianjichenggong': '编辑成功',
    'neirongweikong': '内容不能为空',
    'shenqingyifasong': '申请已发送',

  },
  // 空数据描述
  empty_text: {
    'zanwumeirong': '暂无内容',
    'weizhaodaoneirong': '未找到相关内容',
    'zanwushuju': '暂无数据',

  },
  // 表单通用文本
  form: {
    'qingshuru': '请输入',
    'qingxuanze': '请选择',
    'chazhaoxuanxiang': '查找选项',
    'meiyouxuanxiang': '没有选项',
    'bumen': '部门',
    'wenjian_limit': '最多只能添加 {count} 个文件',
    'zanwuwenjian': '暂无文件',
    'tuozhuaitishi': '点击或拖拽至这里<i style="color: #1F2329;font-style: initial;">上传本地文件</i>',
    'tianjiawenjian': '添加文件',
    'xuanzeyunwendang': '选择云文档',
    'xuanzexiangmuwendang': '选择项目文档',
    'xinjianwendang': '新建文档',
    'xinjianbiaoge': '新建表格',
    'xinjianduoweibiaoge': '新建多维表格',
    'wendangmingcheng_placeholder': '请输入文档名称',
    'biaogemingcheng_placeholder': '请输入表格名称',
    'duoweibiaoge_placeholder': '请输入多维表格名称',
    'weimingmingwendang': '未命名文档',
    'bendishangchuan_click': '点击本地上传',
    'zanwujieguo': '暂无结果',
    'sousuoyunwendang': '搜索并选择飞书云文档',
    'sousuoxiangmuwendang': '搜索并选择项目文档',
    'beizhu': '备注',
    'bizhong_tooltip': '录入及展示币种，选定后不可更改',
    'shoujihaoma_error': '手机号码格式不正确!',
    'email_error': 'email格式不正确!',
    'ziduan_empty': '当前字段为必填项，不能为空',
    'wenjianziduan_limit': '文件字段最多 200 个文件',
    'chongshi': '重试',
    'dakai': '打开',
    'qingxuanzebiaoqian': '请选择标签',
    'tianjiabiaoqian': '添加标签',
    'bitian': '必填',
  },
  //添加相关
  dialog_add,
  //删除相关
  dialog_del,
  // 人员相关弹窗
  dialog_staff,
  // 其他通用弹框
  dialog_other,
  //审批
  approval,
  //弹窗
  dialog_people:{

  },
  //详情页（不包含头部信息，仅详情页tabs）
  // detail_header,
  // 详情公共头部
  detail_topbar: {
    'chiyouzhong': '持有中',
    'bufentuichu': '部分退出',
    'quanbutuichu': '全部退出',
    'renmingbi': '人民币',
    'meiyuan': '美元',
    'shenqing_edit': '申请编辑权限',
    'xiugaitouxiang': '修改项目头像',
    'shenqingquanxian': '申请权限',
    'xiangfezerenshenqing': '向负责人<span class="blue">{applyToUser}</span>申请<span class="blue">编辑权限</span>',
    'chazhaogongsi_placeholder': '请输入您想查找的公司或根据输入的内容创建主体公司',
    'touzizhejian_empty': '投资者简称不能为空',
    'queren': '确认',
    'queren_content': '确认发送？',
    'dakaiyindao': '点击这里，在此打开引导',
    'yindaobangzhu': '引导帮助',

  },
  // 详情右侧工具栏
  details_side: {
    'genjinjilu': '跟进记录',
    'wendangziliao': '文档资料',
    'shururichengzhuti': '输入日程主题',
    'shijian': '时间',
    'quantian': '全天',
    'tianjiacanyuren': '添加参与人',
    'tianjiamiaoshu': '添加描述',
    'tianjiarichengtongbu_tip': '添加后该日程将同步到飞书',
    'zhuti_required': '请填写主题',
    'qishishijian_required': '请选择起始时间',
    'canyuren_required': '请添加参与人',
    'quanbushenpi': '全部审批',
    'faqishenpi': '发起审批',
    'dangqianjiedian': '当前节点：',
    'jieshu': '结束',
    'faqiren': '发起人：',
    'quanburicheng': '全部日程',
    'chuangjianricheng': '创建日程',
    'tiaozhuanfeishu': '点击可跳转至飞书查看详情',
    'shenpi': '审批',
    'richeng': '日程',
    'toupiao': '投票',
    'faqishenqing': '发起申请',
    'shenpizhong': '审批中',
    'yitongguo': '已通过',
    'yijujue': '已拒绝',
    'yichexiao': '已撤销',
    'xuanzeshenpimingcheng': '选择审批名称',
    'tongguohouguidang': '审批通过后，文件自动归档到‘文档资料’',
    'xuanzewenjian': '选择文件',
    'shangchuanshibai': '上传失败',
    'chongxinshangchuang': '重新上传',
    'tijiaohou_tongbu': '提交后该审批将同步到飞书',
    'xiangguanxiangmu': '相关项目',
    'xiangguanjijin': '相关基金',
    'xiangguantouzizhe': '相关投资者',
    'yiwancheng': '已完成',
    'jinxingzhong': '进行中',
    'fabuyu': '发布于',
    'toupiao_placeholder': '请输入投票意见',
    'lijitoupiao': '立即投票',
    'piao': '票',
    'yingcanyu_num': '应参与{num}人',
    'yicanyu_num': '已参与{num}人',
    'quanbutoupiao': '全部投票',
    'faqitoupiao': '发起投票',
    'yichutoupiao': '移除投票',
    'shanchuqueren': '删除确认',
    'yichu_tip': '投票将从本阶段中移除，但不会删除',
    'toupiaoxiangqing': '投票详情',
    'weitoupiao': '未投票',
    'yitoupiao': '已投票',
    'shangweitoupiao_num': '{unParticipate}人尚未投票',
    'toupiaocanyuren': '投票参与人',
    'toupiaojieguo': '投票结果',
    'toupiaoyijian': '投票意见',
    'toupiaoshijian': '投票时间',
    'fasongtixing': '发送提醒',
    'fasongzhong': '发送中',
    'bianjitoupiao': '编辑投票',
    'toupiaozhuti': '投票主题',
    'toupiaozhuti_placeholder': '请输入投票主题',
    'toupiaoren_placeholder': '请选择投票参与人',
    'xuanxiang': '选项',
    'xuanxiang_placeholder': '请输入选项',
    'tongyi': '同意',
    'foujue': '否决',
    'qingxuanze_canyuren': '请至少选择1位投票参与人',
    'qingtianjiaxuanxiang': '请至少添加2个选项',
    'quanbu': '全部',
    'huifu': '回复',
    'gongtongchuangjianzhe': '共同创建者',
    'pinglun': '评论',
    'ren': '人',
    'fenlei': '分类',
    'gongchuangzhe': '共创者',
    'tianjia_gongchuangzhe': '添加共创者',
    'fabu': '发布',
    'tianjiajilu_placeholder': '添加项目跟进，记录重要进展',

  },
  other_page: {
    'xmgl_desc': '投资项目全生命周期管理，覆盖项目入库、尽调、交割、退出等不同环节，使用数据智能化辅助项目推进效率',
    'xmgl_startbtn': '开启投前项目管理之旅',
    'ytgs_desc': '投后管理规范项目的治理，提高项目的增值能力和投资的增长效率，提升管理运营能力，使资本运作效果最大化',
    'ytgs_startbtn': '开启投后项目管理之旅',
    'jjgl_desc': '基金主数据管理，智能化基金全生命周期运营，投资者及投资者关系管理，投资组合及交易管理，穿透式多维度提升基金运营效率',
    'jjgl_startbtn': '开启基金管理之旅',
    'sjsj_desc': '数据收集用于收集被投企业的财务、业务等方面的指标数据。支持自动发送收集邮件，提升投后信息收集工作的效率，减少沟通成本。',
    'sjsj_startbtn': '开启数据收集之旅',
    'tzzgl_desc': '募资全流程管理，全方位了解募资线索、投资者资格认证及投资者持仓情况，穿透式投资组合分析提升投资者关系管理及基金运营效率',
    'tzzgl_startbtn': '开启投资者管理之旅',
    'wufangwenquanxian': '您暂无"{pName}"的访问权限',
    'xiangfuzeren_shenqing': '向负责人<span class="admin">{userName}</span>申请',
    'chakanquanxian': '查看权限',
    'bianjiquanxian': '编辑权限',
    'zanwuyingyongquanxian': '您暂无该应用访问权限',
    'lianxiguanliyuan': '如需请联系管理员',
    'fanwenshuju': '您访问的数据已不存在',
    'renmingbi': '人民币',
    'meiyuan': '美元',
    'toupiaofaqiyu': '%{originator} 发起于 %{createdAt}',
    'qingshurujianyi': '请输入投票建议',
    'toupiaoyishanchu': '投票已删除',
    'toupiao_quanxian': '您没有本次投票的参与权限 <br />如有疑问请联系投票发起人{originator}',
    'xiangmuyishanchu': '项目已删除',
    'xiangmutoupiao': '项目投票',
    'fuwuxieyi': '服务协议',
    'yinsitiaokuan': '隐私条款',
    'tongyitiaokuan': '同意服务协议及隐私条款',
  }
}