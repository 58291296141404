// 数据收集
export default {
  suoyouxiangmu: '共{ total }个项目',
  biaoti: '标题',
  fabushijian: '发布时间',
  shixiaoshijian: '失效时间',
  xiangmulianxiren: '项目联系人',
  zhuangtai: '状态',
  caozuo: '操作',
  shujushouji: '数据收集',
  quanbujiazaiwan: '全部数据加载完毕',


  // 自动发送
  zidongfasong: '自动发送',
  shoudongfasong: '手动发送',
  qiyonghoufasongyoujian: '启用后，每季度结束后将自动向投后项目联系人发送填报提醒邮件',
  sousuowenan: '搜索项目、公司名称',
  quanbu: '全部',
  yiqiyong: '已启用',
  weiqiyong: '未启用',

  xiangmumingcheng: '项目名称',
  fasongshijian: '发送时间',
  tianbaobiaodan: '填报表单',
  qijinyong: '启/禁用',

  jidujieshuhoudi: '季度结束后第',
  tian: '天',
  xuanzejieshouren: '请选择接收邮件的联系人',
  weizhaodaoxiangguanneirong: '未找到相关内容',
  tianjialianxiren: '添加联系人',
  queding: '确定',
  quxiao: '取消',
  shijian: '时间',
  shi: '时',

  xingming: '姓名',
  bitian: '必填',
  zhiwei: '职位',
  lianxidianhua: '联系电话',
  youxiang: '邮箱',
  weixin: '微信号',
  jianjie: '简介',

  shuruzhengshu: '请输入正整数',
  baoliulianxiren: '请至少保留一个联系人',
  xuanzeshibai_tixing: '未能成功选择该联系人，请先填写该联系人邮箱。',
  xiugai_chenggong: '修改成功',
  xiugai_shibai: '修改失败',
  zhidinglianxiren: '请至少指定一个项目联系人',
  xuanzexiangmu: '请先选择项目',
  tianbaobiaodan: '填报表单',
  bianjilianxiren: '编辑联系人',
  bianji_chenggong: '编辑成功',
  tianjia_chenggong: '添加成功',

  biaoti: '标题',
  yaoqingtianbao: '邀请您参与信息填报',
  zuhu: '租户',
  shuruyanzhengma: '请输入6位验证码',

  // dataReport
  yuce: '预测',
  shixiao_xiaoshi: '{hour}小时后失效',
  shixiao_fenzhong: '{minute}分钟后失效',
  shixiao_tian: '{day}天后失效',
  shixiao_mingtian: '明天失效',

  tijiao_chenggong: '提交成功',
  lianjieshixiao: '链接已失效',
  meiyoushizhizhibiao:'负责人没有设置任何指标',
  shangchuanwenjian: '上传文件',
  shangchuanshibai: '上传失败',
  chongxinshangchuan: '重新上传',
  tijiao: '提交',
  querentijiao: '确认提交',
  tijiaohoubukexiugai: '提交后不可修改。是否确认提交',
  zhouqi: '报告期',
  shujutianbao: '数据填报',
  yijibao: '一季报',
  diyijibao: '第一季报',
  dierjibao: '第二季报',
  disanjibao: '第三季报',
  disijibao: '第四季报',
  bannianbao: '半年报',
  sanjibao: '三季报',
  nianbao: '年报',


  // detailDialog
  fabuyu: '发布于',
  shixiaoyu: '失效于',
  tianbaoren: '填报人',
  bohui: '驳回',
  bohuiyuanyin: '驳回原因',
  shurubohuiyuanyin: '请输入驳回原因',
  queren: '确认',
  querentongguo: '确认通过',
  querentongguo_wenan: '通过后将把信息收集的指标数据更新到相应的被投项目。是否确认通过？',
  fugaiqueren: '覆盖确认',
  daitijiao: '待提交',
  yiqueren: '已确认',
  daiqueren: '待确认',
  fasongyichang: '发送异常',
  yuqiweitijiao: '逾期未提交',
  jintian: '今天',
  zuotian: '昨天',
  bohuichenggong: '驳回成功',
  bohuishibai: '驳回失败',
  querenchenggong: '确认成功',
  querenshibai: '确认失败',

  detailReport: {
    info: '该公司已存在相同的报告期 {cover}，确认后将覆盖原报告期数据，是否确认？'
  },
  // list
    qingxuanze: '请选择',
    sousuobiaoti: '搜索标题',
    fasongyichang: '邮件发送异常，请检查邮箱配置后再次尝试发送。异常原因',
    chongxinfasong: '重新发送',
    fuzhilianjie: '复制链接',
    shanchu: '删除',
    querenshanchu: '确认删除',
    shanchuqueren: '删除后不可恢复。是否确认删除',
    tianbaolianjieshixiao: '填报链接将在发布10天后失效',
    shanchu_chenggong: '删除成功',
    shanchu_shibai: '删除失败',
    chongxinfasong_chenggong: '重新发送成功',
    chongxinfasong_shibai: '重新发送失败',
    dengluyanzhengma: '登录验证码',
    fuzhichenggong: '复制成功',
    bohuiliyou: '请填写驳回理由',


    // manualSendDialog
    shujushouji_fasong: '发送数据收集',
    shujushouji_chuangjiann: '创建数据收集',
    baogaoqi: '报告期',
    shiji: '实际',
    tianjia: '添加',
    xuanzexiangmu: '请选择项目',
    xiangmu: '项目',

    lianxiren: '联系人',
    yulantianbaobiaodan: '预览填报表单',
    xuanzebaogaoqi: '选择报告期',
    xuanzexiangmu: '选择项目',
    shangyibu: '上一步',
    xiayibu: '下一步',
    lijifasong: '立即发送',
    baoliubaogaoqi: '请至少保留一个报告期',
    qingxuanzebaogaoqi: '请选择报告周期',
    xuanzexiangmujilianxiren: '请选择项目及联系人',
    qingxuanzelianxiren: '请选择联系人',
    fasong_chenggong: '发送成功',
    fasong_shibai: '发送失败',
    bunengchongfutianjia: '不能添加重复项目',
    baoliugongsi: '请至少保留一个项目',
    chongxinfabu: '重新发布',
    chongxinfabu_wenan: '重新发布后将重置填报有效期，并再次向联系人发布填报邮件。是否确认重新发布？',





}