export const allTabs = {
    // 暂写详情页公共
    com: {
        xiangmuguanli:'投前项目',
        yitougongsi:'投后项目',
        yue:'月',
        jidu:'季度',
        geshu:'个数',
        nian:'年',
        piao:'票',
        queren:'确认',
        chongzhi:'重置',
        zuijinsannian:'最近三年',
        zuijinliunian:'最近六年',
        zidingyi:'自定义',
        yi:'亿',
        wan:'万',
        qian:'前',
        hou:'后',
        guanbi:'关闭',
        shenpizhong:'审批中',
        chongfubiangeng:'重复变更',
        baocunchenggong:'保存成功',
        zanwubianquanxian:'暂无编辑权限',
        zanwuneirong:'暂无内容',
        chuangjianxiangmu:'创建项目',
        yitongguo:'已通过',
        yijujue:'已拒绝',
        yichexiao:'已撤销',
        weifaqi:'未发起',
        bianji: '编辑',
        wanyuan:'万元',
        zhuangtai:'状态',
        daochu:'导出',
        daochuzhong:'导出中',
        huilv:'汇率',
        lishi:'历史',
        xiangqing:'详情',
        yichu:'移除',
        gengduo:'更多',
        yuan:'元',
        quanbu:'全部',
        xiangmu:'项目',
        xiangmulianxiren:'项目联系人',
        weikaishi:'未开始',
        jingxingzhong:'进行中',
        xiugaishibai:'修改失败',
        yijieshu:'已结束',
        shanchu: '删除',
        quxiao: '取消',
        mingcheng:'名称',
        queding: '确定',
        tianjia:'添加',
        xiazai:'下载',
        tian:'天',
        shangyibu:'上一步',
        xiayibu:'下一步',
        lijifasong:'立即发送',
        guanbi:'关闭',
        chuangjian:'创建',
        quedingshanchuma: "确定删除吗?",
        bitian: '必填',
        qingshuru:'请输入',
        xuanze_X_M:'选择项目',
        fuzhilianjie:'复制链接',
        qingxuanze:'请选择',
        jianjie:'简介',
        yishanchu:'已删除',
        ciwenjian:'此文件',
        faqiren:'发起人',
        jintutouhou:'进入投后',
        zanwushuju:'暂无数据',
        leijishuju:'累计数据',
        jiezhidao:'截止到 {date}',
        yidongdaoci:'移动到此',
        shanchuqueren:'删除确认',
        fasongchenggong:'发送成功',
        fasongshibai:'发送失败',
        shezhichenggong:'设置成功',
        caozuochenggong:'操作成功',
        gengxinchenggong:'更新成功',
        xiaoyanbutongguo:'校验不通过',
        bianjichenggong:'编辑成功',
        shanchuchenggong:'删除成功',
        yichuchenggong:'移除成功',
        chankanxiangqing:'查看详情',
        tianjiachenggong:'添加成功',
        dangqianjiedian:'当前节点',
        chuangjianchenggong:'创建成功',
        qingsuxanze_X_M:'请选择项目',
        qingxiansuxanze_X_M:'请先选择项目',
        qingxuanze_P:'请选择联系人',
        weizhaodaoneirong:'未找到相关内容',
        neirongbukong:'内容不能为空',
        quedingyaoshanchu:'确认要删除{name}吗？',
        noedit:'您没有编辑权限，请联系项目负责人开通权限！'
    },
    //引导帮助
    guide:{
        shouqi:'收起',
        kaishitouzi:'开始投资',
        jijinguanli:'基金管理',
        guanli_T_Z_Z:'管理投资者',
        add_M_Z:'添加募资基金，进行召款',
        qianyuerenjiao:'签约认缴',
        kuaisu_K_M:'快速开启募资',
        kuaisu_K_T:'快速开启投资',
        touhouguanli:'投后管理',
        wansahnxinxi:'完善信息',
        wanshan_first:'完善信息，开启投资第一步',
        qidongtouzi:'启动投资流程',
        tongguoq_A_F:"通过'权益管理'添加第一笔投资",
        wanshan_T_F:'完善投资者信息，开启募资第一步',
        shoujiwansahn:'收集并完善投资数据',
        tuijiantouziliucheng:'推进投资流程',
        zhuan_T_F:'转入投后/放弃项目',
        guanjianshuji_S_Z:'关键数据收集及整理',
        tongguo_Q_First:"通过权益管理'添加第一笔投资",
        Z_T_W_G:'增资、退出、未参与等股权变更',
        touhou_shou_ji:'投后定期数据收集',
        touzishujufenxi:'投资数据统计分析',
        touzizhe_R_Z:'投资者资格认证',
        add_T_Z:"添加并维护投资者信息，准备召款",
        add_Z_W:'添加召款数据，完成资金准备',
        add_TQ_T:'添加投前项目或新增投后项目，开始投资'
    },

    // tab栏
    tabs: {
        name: '{name}',
        // 投前
        xiangmugaikuang: '项目概况',
        touziliucheng: '投资流程',
        guanjianzhibiao: '关键指标',
        touzijihua: '投资计划',
        wendangziliao: '文档资料',
        gongkaixinix: '公开信息',
        // 投后
        touzigaikuang: '投资概况',
        gongsixinxi: '公司信息',
        quanyiguanli: '权益管理',
        jiesuofangan: '解锁方案',
        // 基金
        jijingaikuang: '基金概况',
        jibenxinxi: '基本信息',
        touzizuhe: '投资组合',
        yejizhibiao: '业绩指标',
        touzizheguanli: '投资者管理',
        zijiliushui: '资金流水',
        // 投资者管理
        zibenzhanghu: '资本账户',
        chuantoubiaodi: '穿透标的',
        // 标签
        addbiaoqianye: '添加标签页',
        guanlibiaoqianye: '管理标签页',
    },
    // 联系人
    contact: {
        lianxiren: '联系人',
        tianjialianxiren: '添加联系人',
        bianjilianxiren: '编辑联系人',
        shanchulianxiren: '删除联系人',
        xinzenglianxiren:'新增联系人',
        feishuzhong_open:'请在飞书应用内打开',
        
        xingming: '姓名',
        zhiwei: '职位',
        lianxidianhua:'联系电话',
        youxiang:'邮箱',
        weixinhao:'微信号', 
        fuzhichonggong:'复制成功',
        gailiulanqibuzhichifuzhi:'该浏览器不支持复制'

    },
    // 账户
    account: {
        zhanghu: '账户',
        tianjiazhanghu: '添加账户',
        bianjizhanghu: '编辑账户',
        shanchuzhanghu:'删除账户',
        huming:'户名',
        zhanghao:'账号',
        kaihuyinhang:'开户银行',
        kaihuzhihang:'开户支行',
        beizhu:'备注',
        tishi:'提示',
        querenshanchu_Z_H:'确认删除 <span style="color: #3e74ca">{p}</span> 吗？',
        qingshurubeuzi:'请输入备注',
        qingshuruyinhangkaihumingcheng:'请输入银行开户名称',
        qingshuruzhanghao:'请输入账号',
        qingshurukaihuyinhang:'请输入开户银行',
    },
    // 文档资料
    files:{
        share: '分享',
        sousouwendang:'搜索文档',
        zanwuwenjian:'暂无文件',
        chuangjianyu:"创建于",
        qingxuanze_W_D:'请选择文档标签',
        gengxinyu:"更新于",
        xitongbiaoqian:'系统标签',
        xinjianbiaoqian:'新建标签',
        copyLink: '复制链接',
        wendangziliao:'文档资料',
        weimingmingwendang:'未命名文档',
        weimingmingbiaoge:'未命名表格',
        weimingmingduoweibiaoge:'未命名多维表格',
        weimingmingwenjianjia:'未命名文件夹',
        chazhaoxinjian:'查找或新建标签',
        zidingyibiaoqian:'自定义标签',
        suoyiuzhe:'所有者',
        chongxinshangchuan:'重新上传',
        gengxinshijian:'更新时间',
        xiugaishijian:'修改时间',
        chuangjianshijian:'创建时间',
        qingkongsaixuan:'清空筛选条件',
        anminngcheng:'按名称',
        xinwenjianyouxian:'新文件优先',
        jiuwenjianyouxian:'旧文件优先',
        zuijinxiugai:'最近修改',
        tianjiawenjian:'添加文件',
        shangchuanwenjian:'上传文件',
        xuanzewenjian:'选择文件',
        xuanzewendang:'选择文档',
        qingshuruwendangmingcheng:'请输入文档名称',
        shangchuanbendiwenjian:'上传本地文件',
        xuanzeyunwendang:'选择云文档',
        qingxuanzeyunwendang:'请选择云文档',
        xuanzefeishuwendang:'选择飞书文档',
        qingxuanzefeishuwendang:'请选择飞书文档',
        xuanzexiangmuwendang:'选择项目文档',
        xinjianwendang:'新建文档',
        xinjianbiaoge:'新建表格',
        xinjiandianzibiaoge:'新建电子表格',
        xinjianduoweibiaoge:'新建多维表格',
        xinjianwenjianjia:'新建文件夹',
        wenjianjiamingcheng:'文件夹名称',
        tianjiayiyouwendang:'添加已有文档',
        sousuobinxuanzefeishuwendang:'搜索并选择飞书云文档',
        biaoqian:'标签',
        xinjianchenggong:'新建成功',
        tianjiabiaoqian:'添加标签',
        xuanzebiaoqian:'选择标签',
        wendangbiaoqian:'文档标签',
        wendangbiaoti:'文档标题',
        qingshuruwendangbiaoti:'请输入文档标题',
        qingshurubiaogebiaoti:'请输入表格标题',
        qingshuruduoweibiaogebiaoti:'请输入多维表格标题',
        qingshuruwenjianjiamingcheng:'请输入文件夹名称',
        qingxianbaocunhuoquxiao:'请先保存或取消当前新建',
        wenjianjiacengjibunengchaoguo:'文件夹层级不能超过三级',
        wushangchuanchenggongwenjian:'无上传成功文件',
        chuangjianfuben:'创建副本',
        chongmingming:'重命名',
        yidongdao:'移动到',
        shanchuhou:'删除后，将同时从相关文档中移除该标签',
        shangchuandaodangqianxiangmu:'上传到当前项目',
        zaiyixianeirongbeiyinyong_span:'<span style="color: #3e74ca">{name}</span>”在以下内容中被引用，将同步删除对应附件：',
        jiangyidongdoa:'将“<span style="max-width:340px;display:inline-block;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;">{name}</span>”移动到...',
        zaiyixianeirongbeiyinyong:'{filename}在以下内容中被引用，将同步删除对应附件：',
        shanchuwenjiajiahou:'删除文件夹后，将同时删除其下的文件夹和文档。是否确认删除？',
        zanbuzhichifeishuwendang:'暂不支持下载在线文档，请在飞书云文档中进行操作',
    },
    // 投资流程
    investmentProcess:{
        jieduankaishishijian:'阶段开始时间',
        guanlianyiyou_T_P:'关联已有投票',
        xuanzeriqi:'选择日期',
        lijitoupiao:'立即投票',
        qingshuru_T_J:'请输入投票建议',
        chuangjianhou_T_b:'创建后该审批将同步到飞书',
        tuijinjieduan:'推进阶段',
        toupiao_B_W_K:'投票内容不能为空',
        biangengdaocijieduan:'变更到此阶段',
        liuchenglocktips:'流程已锁定,如需解锁,请联系管理员',
        liuchengunlocktips:'流程已锁定,点击解锁流程',
        jiesuoliucheng: '解锁流程',
        toupiaochenggong:'投票成功',
        toupiao_Y_B_S:'投票将从本阶段中移除，但不会删除',
        jiesuoliuchengtip: '解锁后可变更流程阶段,确认解锁？',
        quxiao: '取消',
        jiesuo: '解锁',
        yiwancheng:'已完成',
        yingcanyu:'应参与{num}人',
        yichutoupiao:'移除投票',
        yicanyu:'已参与{num}人',
        jieduanxinxi:'阶段信息',
        biangengchenggong:'变更成功',
        qingtianxieyuanyin:'请填写原因',
        biangengjieduan:'变更阶段',
        guanlianshenpi:'关联审批',
        guanlianyiyou:'关联已有审批',
        yichushenpi:'移除审批',
        zaicifaqi:'再次发起',
        faqishenpi:'发起审批',
        fabuyu:'发布于',
        shenpi:'审批',
        toupiao:'投票',
        giuanliantoupiao:'关联投票',
        faqitoupiao:'发起投票',
        guanlianyi_T_P:'关联已有投票至本阶段',
        guanlian:'关联',
        renwu:'任务',
        jieshu:'结束',
        bingjinru:'并进入',
        fangqiyuanyin:'放弃原因',
        weizhaodao:'未找到可关联的审批<br>请确认是否已在投资云发起',
        weizhaodao_T_P:'未找到可关联的投票<br>请确认是否已在投资云发起',
        qingxuanzejieduan:'请选择阶段',
        jieduanweiwancheng:'阶段还未完成',
        shenpi_Y_C_B_S:'审批将从本阶段中移除，但不会删除',
        xiangmuxinxi:'项目信息及文档等将同步转入投后',
        liuchengsuoding:'投资流程将锁定不可变更,如需解锁,可联系管理员',
        haiyouweiwancheng:'还有未完成的阶段内容',
        tongguo_G_D:'通过投资云发起的审批，可关联至本阶段；通过飞书审批中心发起的审批，请先归档至投资云。',
        ruxubiangeng:'如需变更阶段，请先完成当前阶段必要内容',
        gaixiangmiusuoyou:'该项目所有阶段及内容将按最新投资流程进行更新',
        gaijieduan:'该阶段在最新投资流程中已被删除，请重新选择需要变更的阶段',
        dangqianjieduan:'当前投资流程已经发生变化，阶段及内容将按最新投资流程进行更新',
        shoucitouzixiangmu:'首次投资项目，转入投后将自动创建投后项目数据'
    },
    // 关键指标
    indicators:{
        xianshiyuce:'显示预测',
        baogaoqi:'报告期',
        nianyijibao:'年一季报',
        dierjidu:'第二季度',
        niandierjidu:'年第二季度',
        disanjidu:'第三季度',
        niandisanjidu:'年第三季度',
        disijidu:'第四季度',
        niandisijidu:'年第四季度',
        nianbannianbao:'年半年报',
        niansanjibao:'年三季报',
        niannianbao:'年年报',
        qingzhishao_B_L:'请至少保留一个报告期',
        qingxuanze_Z_Q:'请选择报告周期',
        qingxuanze_X_L:'请选择项目及联系人',
        qingzhishao_B_L_G:'请至少保留一个项目',
        qingxuanzebaogaoqi:'请选择报告期',
        tongbi:'同比',
        huanbi:'环比',
        tianjiajilu:'添加记录',
        bianjijilu:'编辑记录',
        xiangbiyuci:'相比预测',
        xueze_Y_G:'选择云文档：输入关键字查询',
        dengluyanzhengma:'登录验证码:',
        yijibao:'一季报',
        bannianbao:'半年报',
        sanjibao:'三季报',
        nianbao:'年报',
        ninkeyitongguo:'您可以通过',
        gongzuotai:'工作台',
        chankanlianxiren:'查看和审核项目联系人的填报情况',
        shezhidangqian:'设置当前被投项目的分析维度',
        weineng_C_G_R:'未能成功选择该联系人，请先填写该联系人邮箱',
        shice:'实测',
        shiji:'实际',
        yuce:'预测',
        buneng_T_C_F:'不能添加重复项目',
        yutianlan:'预览填报表单',
        xuanzebaogaoqi:"选择报告期",
        shujushouji:'数据收集',
        fasongshuju:'发送数据收集',
        qingxuanze_Y_L:'请选择接收邮件的联系人',
        chuangjianshuju:'创建数据收集',
        xiangfasongren:'向项目联系人发送填报链接',
        dianjiadd:"点击添加",
        gaibaogaoqi:'该报告期已存在',
        fugaiqueren:'覆盖确认',
        yicunzai_x_t:'已存在相同报告期',
        shifoufugai:'是否覆盖数据？',
        zhibiaoshezhi:'指标设置',
        qingxuanze_z_d:'请选择字段',
        haiwei_a_Z_B:'还未添加指标，',
        func:{
            name:'指标库',
            ruguo_Z_B_K:'如果没有找到您需要的指标，请在后台 >{markA} 中创建',
        },
        shachu_Q:' 删除报告期将同时删除报告期对应的文件，此操作不可恢复。是否确认删除？',

    },
    // 投资计划
    investmentPlan:{
        gongsixinwen:'公司新闻',
        jinpinenxi:'竞品分析',
        zuceziben:'注册资本',
        chiyoubili:'持有比例',
        qingxuanzeriqi:'请选择日期',
        tuichushouyi:'退出收益',
        tuichu_F_T_Z:'退出收益分配投资',
        guanlifeizhank:'管理费召款',
        chuzizhank:'出资召款',
        xiangmushouyi:'项目退出收益',
        qitashouru:'其他收入',
        tuichushouyif:'退出收益分配',
        fenhongshouyi:'分红收益分配',
        qitashouyifenpei:'其他收益分配',
        xiangmutouzi:'项目投资',
        qitazhichu:'其他支出',
        touhouguzhi:'投后估值',
        touhouguzhi_G:'投后估值(公司)',
        jiaoyiriqi:'交易日期',
        xuanzejiaoyiriqi:'请选择交易日期',
        jiaoyileixing:'交易类型',
        touzijine:'投资金额',
        leixing:'类型',
        qingxuanzeleixing:'请选择类型',
        leijitouzi:'累计投资',
        chigubili:'持股比例',
        jijintouhou_C_G:'基金投后持股比例',
        qingxuanzejijin:'请选择基金',
        qingxianxuanzejijin:'请先选择基金',
        jiaoyihou_Z_C:'交易后注册资本',
        jiaoyihou_C_G:'交易后持股比例',
        jiaoyihou_C_J_Z:'交易后持有价值',
        niqianyue:'拟签约日期',
        touzileixing:'投资类型',
        gongsiziben:'公司注册资本',
        touqianguzhi:'投前估值',
        touzixinix:'投资信息',
        benlunrongzi:'本轮融资金额',
        ben_R_Z:'本轮总融资金额',
        zuceziben_D_J:'注册资本单价',
        shanchutouzi:'删除投资',
        tianjiatouzi:'添加投资',
        shanchujijin:'删除基金',
        tianjiajijin:'添加基金',
        queren_YL:'预览&确认',
        benluntouhou:'本轮公司投后估值',
        benlun_T_H_Z:'本轮公司投后注册资本',
        ben_T_Q:'本轮公司投前估值',
        weicanyujijin:'未参与基金',
        qianyueDate:'签约日期',
        hetongDate:'合同签约日期',
        biangengqian:'变更前',
        jijin:'基金',
        lunci:'轮次',
        jine:'金额',
        qingshurujine:'请输入金额',
        touzi:'投资',
        jijin_T_Z:'基金投前注册资本',
        touqian_Z_J:'投前注册资本(基金)',
        chiyouzhuceziben:'持有注册资本',
        touhouchiyou_Q_K:'投后总持有情况',
        jijintou_T_H_J_Z:'基金投后持有价值',
        chiyoujiazhi:'持有价值',
        touzijihua:'投资计划',
        Add_touzijihua:'添加投资计划',
        zijinliushui:'资金流水',
        add_liushui:'添加流水',
        yoingyujisuan:'用于IRR计算及统计使用',
        querenshanchu_Z_J:'确认删除资金流水？',
        yicunzai_Date_noAdd:'已存在 {date}, 对应的 未参与轮次 记录，不能重复添加',
        yicunzai_J_L:'已存在 {data} 对应的 {type} 记录，不能重复添加。是否编辑已有记录？',
        ruo_Y_T:'若未找到对应基金，请检查本次交易日期前是否已添加对应投资记录',
        ciqian_Q_G:'此前已投资但本轮未参与的基金，确认后将同时添加“未参与轮次”的变更记录。',
        gaixiangmu_H_L_S:'该项目已转入投后，后续流水可至投后项目进行调整',
        meiyoutouzi:'使用投资计划，可在项目前期对交易及持有情况进行规划；协议签署后<br>可记录本次投资明细；投资计划将作为交易数据随项目一同转入投后'
    },
    // 公开信息
    publicInfo:{
        gongkaixinxi: '公开信息',
        dangerTitle: '启用风险监控',
        dangerTitleIng: '监控中',
        dangerTipContent: '监控内容：工商变更、法律诉讼、行政处罚、经营异常',
        okText: '确定',
        cancelText: '取消',
        dangerOpenModalTitle: '开启风险监控',
        dangerCloseModalTitle: '取消风险监控',
        dangerOpenModalContent: '开启后可监控工商变更、法律诉讼、行政处罚、经营异常，确认开启？',
        dangerCloseModalContent: '取消监控将无法接收【动态推送】，是否确认取消？',
        dangerOversizeModalContent: '已达到监控项目数量上限，无法继续添加风险监控，如需升级请联系鲸准销售人员。',
        dangerOpen: '监控成功',
        dangerClose: '已取消监控',
        chenglishijian:'成立时间',
        gongsi_G_W:'公司官网',
        zuce_D_Z:'注册地址',
        faren:'法人代表',
        leiji_J_E:'累计融资金额',
        rongzilishi:'融资历史',
        zuixinguzhi:'最新估值',
        gongsihuaxiang:'公司画像',
        gongkaixinix:'公开信息',
        gongsigaikuang:'公司概况',
        chakaigengduo:'查看更多',
        xiugaitouzizhequan:'修改投资者全称',
        xiugaizhuti:'修改主体公司',
        zhutibunengkong:"主体公司不能为空",
        qingshuru_T_Q_C:'请输入投资者主体全称',
        qing_T_Z_Q:'请输入要投资的公司主体全称',
        guanlianzhuti:'关联主体公司',
        meizhao_Z_S:'没有找到您查找的公司 <br />您也可以根据输入的内容创建主体公司',
        qingshuru_C_Z:'请输入您想查找的公司 <br />或根据输入的内容创建主体公司',
        meiyou_C_G:'没有找到您查找的投资者主体 <br />您也可以根据输入的内容创建投资者主体全称',
        qing_G_Q:'请输入您想查找的投资者主体 <br />若投资者为个人，请直接点击确定',
        xiangmu_C_K:'项目创建成功后，您可以在项目详情中查看公开信息',
        tou_G_C:'投资者创建成功后，您可以在投资者详情中查看公开信息',
        guanliamgongsi:'关联主体公司，获取新闻、融资历史及竞品等公开信息'
    },

    // 投后
    // 投资概况
    investmentOverview:{
        gongsi_Q_K:'公司情况',
        gongsi_G_Z:'公司估值',
        new_L_C:'最新轮次',
        rongzijine:'融资金额',
        new_R_Z_J_E:'最新融资金额',
        leiji_R_E:'累计融资金额',
        chiyouqingkuang:'持有情况',
        tuichujine:'退出金额',
        fenghongjine:'分红金额',
        yishixian_J_Z:'已实现价值',
        zhibiao:'指标',
        leijifenhong:'累计分红',
        leijituichu:'累计退出',
    },

    // 基金
    // 基金概况
    fundOverview:{
        quanbuzichan: '全部资产',
        zhaokuang:'召款',
        tuichu:'退出',
        fenhong:'分红',
        fenpei: '分配',
        zhanbi:'占比',
        shuliang:'数量',
        diqufenbu:'地区分布',
        hangyefenbu:'行业分布',
        touziqingkuang:'投资情况',
        jijinguimo:'基金规模',
        touzifenbu:'投资分布',
        zongjiazhi:'总价值',
        jijinjiazhi:'基金价值',
        leijishijiao:'累计实缴金额',
        leijitouzi_J_E:'累计投资金额',
        yitou_X_M:'已投项目数',
        gexiang_Leiji:'各项目累计退出金额 + 累计分红金额',
        weishixian_J_Z:'未实现价值',
        gexiangzong_J_Z:'各项目当前总持有价值',
        yishixian_weishixian:'已实现价值 + 未实现价值',
        touzijindu:'投资进度',
        leiji_shiji:'累计投资金额/实际认缴金额',
        zong_leiji:'总价值 / 累计投资金额',
        leiji_shijirenjiao:'累计实缴金额 / 累计(实际)认缴金额',
        leiji_wei_guanli:'(累计分配金额 + 未实现价值) / (累计实缴金额+累计管理费)',
        leiji_guanlifei:'累计分配金额 / 累计实缴金额',
        genju_J_D_jisuan:'根据资金流水（项目投资、项目退出收益、项目分红收益）金额及交易日期、当前未实现价值计算',
        genju_J_D_jisuan_Biquan:'根据资金流水（项目投资、项目退出收益）金额及交易日期、当前未实现价值计算',

        gexiangmu_L_J: '各项目累计退出金额'
    },
    // 投资组合
    fundInvestment:{
        yitouxiangmu:'已投项目',
        dangqianchigu:'当前持股',
        biangeng_date:'变更日期',
        biangeng_type:'变更类型',
        yishixian_lei_T_F:'已实现价值:累计退出金额 + 累计分红金额'

    },
    // 业绩指标
    fundKpi:{
        shijirenjiao:'实际认缴金额',
        jijinzhibiao:'基金指标',
        leijituichujine:'累计退出金额',
        leijituichu_Fen:'累计退出金额 + 累计分红金额',
        leijifenhonmgjine:'累计分红金额',
        yueduleiji:'月度累计',
        jiduleijiL:'季度累计',
        nianduleiji:'年度累计',
        shengyu_J_E:'剩余可投金额',
        leijiweishixian:'累计未实现价值',
        leijiyishixian:'累计已实现价值',
        leijishiji_TZ:'累计实缴金额 - 累计投资金额',
        qingxuanze_D_C:'选择需要导出的月份',
        leiji_F_S_J_G:'(累计分配金额 + 累计未实现价值) / (累计实缴金额 + 累计管理费)'
    },
    // 投资者管理
    manageLP:{
        songlanqingkuang:'总览情况',
        leijifenpei:'累计分配金额',
        tianjianiandu:'添加年度',
        leijiguanlifei:'累计管理费',
        zongrenjiao:'总认缴比例',
        leijifeiyong_T:'累计费用分摊',
        jijin_G_F:'基金运营过程中产生的费用总额',
        touzizheguanli:'投资者管理',
        feiyongfen_B:'费用分摊表',
        feiyongfen_Z:'费用分摊总额',
        qingshuru_Z_E:'请输入费用分摊总额',
        niandu:'年度',
        zhishaoyige:'至少添加1个投资者',
        cunzai_C_F:'存在重复年度',
        yitianjiaguo_T:'已添加过该投资者',
        qingshezhi_N_D:'请设置年度',
        qingtianxie:'请填写年度费用分摊总额',
        shanchulie:'删除列',
        shanchuhang:'删除行',
        qingxuanze_N:'请选择年度',
        tianjia_T:'添加投资者',
        bianji_T:'编辑投资者',
        touzhizhe:'投资者',
        chakan_Z_B:'查看资本账户',
        renjiao_C_J:'认缴出资金额',
        renjiaobili:'认缴比例',
        qingshuru_R_J:'请输入认缴比例',
        shijiaochuzie:'实缴出资额',
        shijiao_G_L_F:'实缴管理费',
        feiyongfentan:'费用分摊',
        tou_F_C_F:'投资者分摊的基金运营过程中产生的费用',
        touzi_J_C:'投资者简称',
        shuru_J_C:'请输入投资者简称',
        cha_Add_T:'查找或新建投资者',
        add_T_Z:'新建投资者',
        leijirenjiao:'累计认缴',
        leijirenjiaobili:'累计认缴比例{num}',
        touzizhe_T:'投资者类型',
        qingxuan_T_Z:'请选择投资者类型',
        renjiaojine:'认缴金额',
        shururenjiao:'请输入认缴金额',
        qianshu_X_Y:'协议签署时间',
        zidongjisuan:'自动计算',
        qngshuru_Q_S:'请选择协议签署时间',
        yichunzai_qingshuru:'已存在相同投资者，请重新输入投资者简称',
        geitou_W:'该投资者存在召款分配记录，无法删除!',
        caozuoque:'此操作不可恢复，是否确认删除？',
        tou_Y_C_Z:'投资者简称已存在，请勿重复添加',
        genju_G_F_J:'根据最新认缴比例自动计算各投资分摊金额',
        yichaochu_MAX:'已超出剩余可认缴比例,已为您自动转换为最大比例',
        ren_G_MAX:'认缴金额超出基金规模,已为您自动转换为最大额度',
    },
    //资金流水
    fundFlow:{
        quanbuliushui:'全部流水',
        gongdaoru_L_S:'共导入流水{num}条',
        shezhifenlei:'设置分类',
        daichuli:'待处理',
        daoruliushui:'导入流水',
        addliushui:'添加流水',
        bianjiliushui:'编辑流水',
        fukaufangzhuti:'付款方主体',
        shoukuanfangzhuti:'收款方主体',
        qingshurufukuan:'请输入付款方',
        fukuanfang_H:'付款方银行卡',
        shoukuanfnag_H:'收款方银行卡',
        xinjianyinhang:'新建银行帐户',
        fuifang_Y_H_K:'对方银行卡号',
        weizhaodaojieguo:'未找到相关结果',
        xitongziduan:'系统字段',
        wenjianbiaotou:'文件表头',
        daoru:'导入',
        shourujine:'收入金额',
        zhichujine:'支出金额',
        duifanghuming:'对方户名',
        bugaidaorilie:'不导入该列',
        fukuanjine:'付款金额',
        shuru_F_K:'请输入付款金额',
        newAdd_zhanghu:'新增账户',
        qingxuannze_Y_H_K:'请选择银行卡',
        duifang_Y_H_Z_H:'对方银行账户',
        lijichuangjian:'立即创建',
        gongjilu:'共{num}条记录',
        shouru:'收入',
        zhichu:'支出',
        shuanglie:'双列',
        danlie:'单列',
        tianjia_Y_H_Z_H:'添加银行账户',
        jiaoyifenlei:'交易分类',
        qingadd_FILEL:'请添加导入文件',
        qingxuanze_J_Y:'请选择交易分类',
        file_max:'文件不能大于{size}',
        jinzhichi:'仅支持xlsx、xls、csv类型文件',
        xiazaimoban:'下载模板文件',
        jiaoyijine:'交易金额',
        duifangzuti:'对方主体',
        duifangzhanghao:'对方账号',
        duifangyinhang:'对方银行',
        jiaoyishijian:'交易时间',
        jinejiexi:'金额解析规则',
        xiangmufenhong:'项目分红收益',
        dianjihuotuozhuai:'点击或拖拽至这里，上传本地文件',
        daorubiaotou:'请确保导入Excel的首行是表头',
        jianyishiyong:'建议优先使用“模板”导入',
        daoru_X_F_Z:'导入文件只有一列金额，系统将通过是否存在“负号”来判断收入或支出',
        daoru_S_Z:'导入文件包含两列金额：“收入金额”和“支出金额”',
        max_FILes:'最大文件体积：{size} | 支持的文件格式：{type}',
        liushuishezhi:'将<renderCheckedNames />流水 设置分类',
        shanchulishui:'确定删除<renderCheckedNames />流水吗？删除后无法恢复',
        shanchu_SPAN:'确定删除 <span style="color: #3e74ca">{name}</span> 吗? 删除后无法恢复',
        weineng_FIND:'未能在“{name}”下找到对应的银行账户',
        qing_J_W:'请确保以下流水的交易日期，交易分类，交易金额，对方主体填写完整',
    },
    
    // 投资者管理
    // 资本账户
    tradeInfo:{
        nianfen:'年份',
        yuefen:'月份',
        jine:'金额',
        zongji:'总计',
        bennianfenpeijine:'本年累计分配金额',
        qimoshijiaochuzi:'期末实缴出资余额',
        qimoshouyijine:'期末收益金额',
        shijiaochuzikuan:'实缴出资款',
        leijishijiao:'累计实缴',
        leijifenpei:'累计分配',
        xieyiqianshu:'协议签署日期',
        renjiaochuzie:'认缴出资额',
        jijinjianchen:'基金简称',
        bianjijijin:'编辑基金',
        jijinmingchen:'基金名称',
        fuzeren:'负责人',
        touzizhegaik:'投资者概况',
        nianduhuibao:'年度汇总',
        qingxuanze_J_C:'请选择基金简称',
        cijijin_W_S_F:'此基金下存在该投资者的召款分配记录，无法删除',
        wufashanchu_T_Z_Z:'无法删除投资者',
        cijijin_W_B_J:'此基金下存在该投资者的召款分配记录，无法编辑！',
        gaitouzicunzai_W_S_C:'该投资者投资的以下基金，存在召款分配记录，无法删除!',
    },
    // 投资标的
    ownProject:{
        chuantoubiaodi:'穿透标的',
        chuzijijin:'出资基金',
        dangqianchigu:'当前持股',
        alljijin:'全部基金',

    }

}