import { createStore } from 'vuex'
import { useWindowSize } from '@vueuse/core'
import { fetchValidUsers } from '@/services/sysconfig/admin.js'
import { setI18nLanguage } from '@/utils/langTool.js';
import { initCurrency } from './useCurrencyStore';
import { fetchTipsList } from '@/services/sysconfig/index.js'
import { fetchAppType , fetchDynamicAppName} from '@/services/other/index.js'
import { getSelfInfo, modulePermission } from '@/services/investment/createProject.js'
import { getTenantInfo } from '@/services/sysconfig/admin.js'

import { fetchFindAllUser } from '@/services/use.js'
import { quotaCaliberQueryAll } from '@/services/sysconfig/indicator.js'

const store = createStore({
  state: {
    isLock: false,
    isEdit: false,
    isOwner: false,
    isAdmin: false,
    isDelete: false,
    canChangeDirector: false,
    currencyUnit: '',
    screenWidth: useWindowSize().width.value,
    beforeGroupFilter: null,
    afterGroupFilter: null,
    fundGroupFilter: null,
    lpGroupFilter: null,
    userSelfInfo: {}, // 当前登录用户的用户信息
    tenantInfo: {}, // 当前的租户信息
    showUploadPop: false,
    typeList: [],
    userModulePermission: {}, //数据权限,
    invProgressValidate: false, // 投资流程必填校验
    allUserList: [], // 人员列表 包含离职人员
    tenantId: null,
    validUserList: [], //当前租户内的人员（即用户管理里面的用户）
    newFuncTipsLists: [], //新功能上线需要弹窗提示的列表
    appType: null, //当前应用是自建应用还是商店应用
    larkAppName: '', //用户可自定义的应用名称
    groupListLoading: false,
    isCloseAddWeek: false,
    needOpenSelf: false,
    weekDetailId: '',
    infoCluesFields: [], // 项目线索-收集字段
    infoCollectFields: [], // 数据收集-设计内容-项目概况收集字段
    tzbdCollectFields: [], // 数据收集-设计内容-投资标的收集字段
    tzbdDefaultCollectFields: [], // 数据收集-设计内容-投资标的默认收集字段
    matchRuleAllFields: [], // 规则匹配-全部字段(项目概况和关键指标)
    matchRuleInvestAllFields: [], // 规则匹配-全部字段(投资标的)
    allCaliberList: [], // 所有指标口径，包括已删除
    tzbdTabId: '', // 详情页投资标的-标签tab的id
  },
  getters: {
    caliberList: state => { // 所有指标口径，不包括已删除
      return state.allCaliberList.filter((item: any) => !item.isDeleted)
    },
    caliberMap: state => {
      return state.allCaliberList.reduce((rs: any, opt: any) => {
        rs[opt.code] = opt.name
        return rs
      }, {})
    },
    tenantConfig: state => {
      return state.tenantInfo?.config ? JSON.parse(state.tenantInfo?.config) : null
    },
  },
  mutations: {
    setTenantId(state, tenantId) {
      state.tenantId = tenantId
    },
    setInvProgressValidate(state, val) {
      state.invProgressValidate = val
    },
    setUserModulePermission(state, val) {
      state.userModulePermission = val
    },
    setCurrencyUnit(state, val) {
      state.currencyUnit = val
    },
    setLock(state, val) {
      state.isLock = val
    },
    setEdit(state, val) {
      state.isEdit = val
    },
    setDelete(state, val) {
      state.isDelete = val
    },
    setOwner(state, val) {
      state.isOwner = val
    },
    setAdmin(state, val) {
      state.isAdmin = val
    },
    setCanChangeDirector(state, val) {
      state.canChangeDirector = val
    },
    setScreenWidth(state, val) {
      state.screenWidth = val
    },
    setBoforeGroupFilter(state, val) {
      state.beforeGroupFilter = val
    },
    setAfterGroupFilter(state, val) {
      state.afterGroupFilter = val
    },
    setFundGroupFilter(state, val) {
      state.fundGroupFilter = val
    },
    setLpGroupFilter(state, val) {
      state.lpGroupFilter = val
    },
    setUserSelftInfo(state, val) {
      state.userSelfInfo = val
    },
    setTenantInfo(state, val) {
      state.tenantInfo = val
    },

    setShowUploadPop(state, val) {
      state.showUploadPop = val
    },
    setTypeList(state, val) {
      state.typeList = val
    },
    setAllUserList(state, val) {
      state.allUserList = val
    },
    setValidUserList(state, val) {
      state.validUserList = val
    },
    setNewFuncTipsLists(state, val) {
      state.newFuncTipsLists = val
    },
    setAppType(state, val) {
      state.appType = val
    },
    setLarkAppName(state, val) {
      state.larkAppName = val
    },
    groupListLoadingFunc(state, val) {
      state.groupListLoading = val
    },
    closeAddWeek(state, val) {
      state.isCloseAddWeek = val
    },
    setNeedOpenSelf(state, val) {
      state.needOpenSelf = val
    },
    setWeekDetailId(state, val) {
      state.weekDetailId = val
    },
    setInfoCluesFields(state, val) {
      state.infoCluesFields = val
    },
    setInfoCollectFields(state, val) {
      state.infoCollectFields = val
    },
    setTzbdCollectFields(state, val) {
      state.tzbdCollectFields = val
    },
    setTzbdDefaultCollectFields(state, val) {
      state.tzbdDefaultCollectFields = val
    },
    setMatchRuleAllFields(state, val) {
      state.matchRuleAllFields = val
    },
    setMatchRuleInvestAllFields(state, val) {
      state.matchRuleInvestAllFields = val
    },
    setCaliberList(state, val) {
      state.allCaliberList = val
    },
    setTzbdTabId(state, val) {
      state.tzbdTabId = val
    },

  },
  actions: {
    async initStoreWhenLogin() {
      const res = await getSelfInfo()
      store.commit('setUserSelftInfo', res)
      store.commit('setAdmin', ['0'].includes(res.roleId))
      store.commit('setTenantId', res.tenantId)
      initCurrency(res.tenantId) //初始化货币

      const tenantInfo = await getTenantInfo()
      store.commit('setTenantInfo', tenantInfo)

     
      const allUserList = await fetchFindAllUser({ data: { name: '' } })

      store.commit('setAllUserList', allUserList)
      store.dispatch('getValidUsers')
      store.dispatch('getTipsLists')
      

      // const lang = res?.language || 'zh';
      // setI18nLanguage(lang)
      const allCaliberList = await quotaCaliberQueryAll()
      store.commit('setCaliberList', allCaliberList)
    },
    async userDataPermission(ctx, { assetTypeId, businessCode, dataId }) {
      if (!businessCode || !dataId) return
      let res = await modulePermission({ data: { assetTypeId, businessCode, dataId } })
      ctx.commit('setUserModulePermission', res)
    },
    async getValidUsers() {
      const validUserList = await fetchValidUsers({ data: {} })
      store.commit('setValidUserList', validUserList)
    },
    async getTipsLists() {
      const tipsLists = await fetchTipsList()
      store.commit('setNewFuncTipsLists', tipsLists)
    },
    async getAppType() {
      const { query } = window.__g_router__.currentRoute.value;
      const aid = query.aid
      if(!aid) return;
      const res = await fetchAppType(aid)
      store.commit('setAppType', res)
    },
    async getDynamicAppName() {
      const { query } = window.__g_router__.currentRoute.value;
      const aid = query.aid
      if(!aid) return;
      const res = await fetchDynamicAppName(aid)
      store.commit('setLarkAppName', res)
    },
    setGroupListLoading({}, val) {
      store.commit('groupListLoadingFunc', val)
    },
  },
  modules: {},
  plugins: [],
})

export default store
