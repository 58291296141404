import {ajax} from '@/utils/request';

// 获取应用状态
export async function getTenantStatus(tenantId) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/status?tenantId=${tenantId}`,
  }, [], []);
  return result;
}
// 获取AppAccessToken
export async function getAppAccessToken(extra) {
  const result = await ajax({
    method: 'get',
    url: '/auth/getAppAccessToken',
    ...extra,
  }, [], []);
  return result;
}
// 获取加密的 TenantAccessToken
export const getSignTenantAccessToken = () => {
  return ajax({
    url: '/auth/getSignTenantAccessToken',
  })
}
// 获取加密的 TenantAccessToken 免登
export const getSignTenantAccessTokenLoginSkip = (tenantId) => {
  return ajax({
    url: `/auth/getSignTenantAccessTokenLoginSkip?tenantId=${tenantId}`,
  })
}

// 获取TenantAccessToken
export async function getTenantAccessToken(extra) {
  const result = await ajax({
    method: 'get',
    url: '/auth/getTenantAccessToken',
    ...extra,
  }, [], []);
  return result;
}
// 获取jsApi的ticket
export async function getTicket(extra) {
  const result = await ajax({
    method: 'get',
    url: '/auth/getTicket',
    ...extra,
  }, [], []);
  return result;
}

// 获取jsApi的ticket(根据app_access_token生成)
export async function getAppTicket(extra) {
  const result = await ajax({
    method: 'get',
    url: '/auth/getAppTicket',
    ...extra,
  }, [], []);
  return result;
}

// 获取jsApi的ticket(根据user_access_token生成)
export async function getUserTicket(extra) {
  const result = await ajax({
    method: 'get',
    url: '/auth/getUserTicket',
    ...extra,
  }, [], []);
  return result;
}

// 获取jsApi的ticket, 组件SDK鉴权流程
export async function postTicket(extra) {
  const result = await ajax({
    method: 'post',
    url: 'https://open.feishu.cn/open-apis/jssdk/ticket/get',
    ...extra,
  }, {
    noPrefix: true, //不需要url前缀
    // getContext: true, //返回整个结构体上下文
  });
  return result;
}

export async function login(userId, extra) {
  const result = await ajax({
    method: 'get',
    url: `/auth/${userId}/login?code=${extra}`,
  }, [], []);
  return result;
}
// 衡石登录
export async function loginHs() {
  const result = await ajax({
    method: 'get',
    url: `/bi/hengshi/jwt/login`,
  }, [], []);
  return result;
}
// 换取token
export async function authTokenRefresh() {
  const result = await ajax({
    method: 'get',
    url: `/auth/token/refresh`,
  }, [], []);
  return result;
}